<template>
  <div>
    <span
      v-for="(category, index) in sortedCategories"
      :key="'navigation_subject_category_' + index"
      :id="'navigation_subject_category_' + index"
    >
      <span v-if="index > 0"> | </span>
      <router-link
        :to="{ name: 'categoryId', params: { category: encodeURI(translate['ghwk:subjectcategory'][category]['de']) }}"
        v-html="translate['ghwk:subjectcategory'][category][language]"></router-link>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const localeCompareSupportsLocales = function () {
  try {
    'foo'.localeCompare('bar', 'i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}
export default {
  name: 'SubjectcategoryNavigation',
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate'
    }),
    sortedCategories() {
      let key, keys = []
      for (key in this.translate['ghwk:subjectcategory']) {
        if (this.translate['ghwk:subjectcategory'].hasOwnProperty(key)) {
          keys.push(key);
        }
      }
      return keys.sort((a, b) => {
        return localeCompareSupportsLocales()? 
          this.translate['ghwk:subjectcategory'][a][this.language].localeCompare(
            this.translate['ghwk:subjectcategory'][b][this.language], this.language, {sensitivity: 'accent'}) :
          this.translate['ghwk:subjectcategory'][a][this.language].localeCompare(
            this.translate['ghwk:subjectcategory'][b][this.language])
      })
    }
  }
}
</script>