<template>
  <div>
    <span
      v-for="(location, index) in locations"
      :key="'navigation_subject_location_' + index"
      :id="'navigation_subject_location_' + index"
    >
      <span v-if="index > 0"> | </span>
      <router-link
        :to="{ name: 'location', params: { id: encodeURI(location['o:id']) }}"
        v-html="locationLabel(location)"></router-link>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LocationsNavigation',
  computed: {
    ...mapGetters({
      language: 'language',
      locations: 'locations/locations'
    })
  },
  methods: {
    locationLabel(location) {
      let result = ''
      if (location && typeof location['ghwk:locationlabel'] !== 'undefined' &&
        location['ghwk:locationlabel'].length > 0) {
        result = location['ghwk:locationlabel'].find(prop => prop['@language'] === this.language)
        if (result !== '' && typeof result !== 'undefined') { result = result['@value'] }
      }
      return result
    }
  }
}
</script>