<template>
  <div id="exhibition_list">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- list -->
        <div class="col-lg-8">
          <h2 class="list_title" v-html="translate.global.exponate_ausstellung_zum_thema[language] + ': ' + showCurrentCategory"></h2>
          <ExhibitionList v-if="hasCategoryPhotoInExhibition(photoId)" v-for="(photoId, i) in photosInExhibition" :photoId="photoId" :key="'photolist_item_inex_' + i" />
          <h2 class="list_title" v-html="translate.global.exponate_bestand_zum_thema[language] + ': ' + showCurrentCategory"></h2>
          <ExhibitionList v-if="hasCategoryPhotoInStore(photo)" v-for="(photo, i) in photos" :photoId="photo['o:id']" :key="'photolist_item_instore_' + i" />
        </div>
        <!-- side -->
        <div class="col-lg-4 exhibition_side_col">
          <h1>{{ translate.global.thema[language] }}: {{ showCurrentCategory }}</h1>
          <hr>
          <dl>
            <dt v-html="translate.metadata_label['thematische Kategorie'][language]"></dt>
            <dd><SubjectcategoryNavigation /></dd>
            <dt v-html="translate.metadata_label['Personen'][language]"></dt>
            <dd><PersonsNavigation /></dd>
            <dt v-html="translate.metadata_label['Orte'][language]"></dt>
            <dd><LocationsNavigation /></dd>
          </dl>
          <hr>
          <GolbalListLinks />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import ExhibitionList from '@/components/partials/ExhibitionList.vue'
import SubjectcategoryNavigation from '@/components/partials/SubjectcategoryNavigation.vue'
import PersonsNavigation from '@/components/partials/PersonsNavigation.vue'
import LocationsNavigation from '@/components/partials/LocationsNavigation.vue'
import GolbalListLinks from '@/components/partials/GolbalListLinks.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Category',
  components: {
    'site-header': header,
    ExhibitionList,
    SubjectcategoryNavigation,
    PersonsNavigation,
    LocationsNavigation,
    GolbalListLinks
  },
  data () {
    return {
      currentCategory: null
    }
  },
  watch: {
    '$route' (to) {
      this.setCurrentCategory(decodeURIComponent(to.params.category))
    },
  },
  methods: {
    setCurrentCategory(category) {
      this.currentCategory = (
        typeof this.translate['ghwk:subjectcategory'] !== 'undefined' &&
        typeof this.translate['ghwk:subjectcategory'][category] !== 'undefined' &&
        this.translate['ghwk:subjectcategory'][category]['de'] === category)? category : null
    },
    hasCategoryPhotoInExhibition(id) {
      if (this.photos && typeof this.photos !== 'undefined') {
        let photo = this.photos.find(prop =>
          prop['o:id'] === id &&
          typeof prop['ghwk:subjectcategory'] !== 'undefined' &&
          typeof prop['ghwk:subjectcategory'].find(
            cat => cat['@value'] === this.currentCategory
          ) !== 'undefined'
        )
        return (typeof photo !== 'undefined')? true : false
      }
      return false
    },
    hasCategoryPhotoInStore(photo) {
      if (photo['ghwk:exhibitstatus'][0]['@value'] !== 'im Bestand') {
        return false
      }
      if (photo && typeof photo['ghwk:subjectcategory'] !== 'undefined') {
        let result = photo['ghwk:subjectcategory'].find(
          cat => cat['@value'] === this.currentCategory
        )
        return (typeof result !== 'undefined')? true : false
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photosInExhibition: 'items/photosInExhibition',
      media: 'media/media'
    }),
    showCurrentCategory: function () {
      return (this.currentCategory === null)? '' : this.currentCategory
    }
  },
  created () {
    if (this.$route.params.category) {
      this.setCurrentCategory(decodeURIComponent(this.$route.params.category))

    }
  }
}
</script>