import { render, staticRenderFns } from "./PersonsNavigation.vue?vue&type=template&id=08f8a17d&"
import script from "./PersonsNavigation.vue?vue&type=script&lang=js&"
export * from "./PersonsNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "PersonsNavigation.vue"
export default component.exports