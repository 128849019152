<template>
  <div class="row"> <!--  :id="'exhibition_list_item_' + currentPhotoId" -->
    <div class="col">
      <div :class="isActive + 'py-2 list-bt'">
        <div class="row">
          <div class="col-sm-3 py-2">
            <router-link
              :to="{ name: 'item', params: { id: photoId }}"
              v-html="thumbnail"></router-link>
          </div>
          <div class="col-sm-9 py-2">
            <!-- caption -->
            <div class="list_caption">
              <router-link
                :to="{ name: 'item', params: { id: photoId }}"
                v-html="caption"></router-link>
            </div>
            <!-- location -->
            <dl class="list_location"
              v-if="typeof photo !== 'undefined' &&
              photo !== null && typeof photo['ghwk:locationdisplay'] !== 'undefined' &&
              photo['ghwk:locationdisplay'].length > 0"
            >
              <dt v-html="translate['metadata_label']['Ort'][language]"></dt>
              <dd v-html="location"></dd>
            </dl>
            <!-- date -->
            <dl class="list_date" v-if="typeof photo !== 'undefined' &&
              photo !== null && typeof photo['ghwk:datedisplay'] !== 'undefined' &&
              photo['ghwk:datedisplay'].length > 0">
              <dt v-html="translate['metadata_label']['Datum (Ausgabe)'][language]"></dt>
              <dd v-html="date"></dd>
            </dl>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ExhibitionList',
  components: {},
  data() {
    return { photo: null }
  },
  props: {
    photoId: Number
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      currentPhotoId: 'items/currentPhotoId',
      media: 'media/media'
    }),
    caption: function () {
      let result = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null) {
        if (typeof this.photo['ghwk:caption'] !== 'undefined' &&
          this.photo['ghwk:caption'].length > 0)
        {
          result = this.photo['ghwk:caption'].find(caption => caption['@language'] === this.language)
          if (result !== '') { result = result['@value'] }
        }
      }
      return result
    },
    location: function () {
      let location = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null) {
        if (typeof this.photo['ghwk:locationdisplay'] !== 'undefined' &&
          this.photo['ghwk:locationdisplay'].length > 0)
        {
          location = this.photo['ghwk:locationdisplay'].find(prop => prop['@language'] === this.language)
          if (location !== '' && typeof location !== 'undefined') { location = location['@value'] }
        }
      }
      return location.replace(/(<([^>]+)>)/ig,"")
    },
    date: function () {
      let date = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null) {
        if (typeof this.photo['ghwk:datedisplay'] !== 'undefined' &&
          this.photo['ghwk:datedisplay'].length > 0)
        {
          date = this.photo['ghwk:datedisplay'].find(prop => prop['@language'] === this.language)
          if (date !== '') { date = date['@value'] }
        }

      }
      return date.replace(/(<([^>]+)>)/ig,"")
    },
    thumbnail: function() {
      let image = '<img class="img-fluid thumb" '
      let medium = ''
      let alt = ''
      // thumb src
      if(typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.media !== 'undefined' && this.media !== null && this.media.length > 0) {
        medium = this.media.filter(
          file => file['o:item']['o:id'] === this.photo['o:id'] &&
          file['dcterms:title'][0]['@value'] === 'thumb'
        )
        if (medium.length > 0) {
          image += 'src="' + medium[0]['o:thumbnail_urls']['square'] + '" '
        }
      }
      // alt
      alt = this.caption
      if (alt.length > 0) {
        image += 'alt="' + alt.replace(/(<([^>]+)>)/ig,"") + '"'
      } else {
        image += 'alt="thumbnail"'
      }

      return image + '>'
    },
    isActive: function() {
      return (this.currentPhotoId === this.photoId)? 'activeCol ' : ' '
    }
  },
  methods: {},
  created() {
    this.photo = this.photos.find(item => item['o:id'] === this.photoId)
  }
}
</script>
<style lang="scss">
.list-bt {
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.activeCol {
  background-color: #eeeeee;
}
.list_caption {
  font-size: 1.25rem;
}
</style>