import { render, staticRenderFns } from "./ExhibitionList.vue?vue&type=template&id=7c4cdba6&"
import script from "./ExhibitionList.vue?vue&type=script&lang=js&"
export * from "./ExhibitionList.vue?vue&type=script&lang=js&"
import style0 from "./ExhibitionList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ExhibitionList.vue"
export default component.exports