<template>
  <p class="text-center">
    <router-link
      :to="{ name: 'item'}"
      v-html="translate.global.zur_ausstellung[language]"></router-link> |
    <template v-if="$route.name !== 'exhibition'">
      <router-link
        :to="{ name: 'exhibition'}"
        v-html="translate.global.all_photos[language]"></router-link> |
    </template>
    <router-link
      :to="{ name: 'about'}"
      v-html="translate.global.about_this_presentation[language]"></router-link>
  </p>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GolbalListLinks',
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
    })
  }
}
</script>