<template>
  <div>
    <span
      v-for="(person, index) in persons"
      :key="'navigation_subject_person_' + index"
      :id="'navigation_subject_person_' + index"
    >
      <span v-if="index > 0"> | </span>
      <router-link
        :to="{ name: 'person', params: { id: encodeURI(person['o:id']) }}"
        v-html="fullName(person)"></router-link>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PersonsNavigation',
  computed: {
    ...mapGetters({
      persons: 'persons/persons'
    })
  },
  methods: {
    fullName(person) {
      let fullName = ''
      if (person && typeof person['foaf:givenName'] !== 'undefined') {
        fullName = person['foaf:givenName'][0]['@value']
      }
      if (person && typeof person['foaf:familyName'] !== 'undefined') {
        fullName += (fullName.length > 0)? ' ' : ''
        fullName += person['foaf:familyName'][0]['@value']
      }
      if (fullName.length === 0) {
        fullName = person['dcterms:title'][0]['@value']
      }
      return fullName
    }
  }
}
</script>